import IndexPage from "../components/pages/index";
import Language from "../translations/translator";

const Index = (props) => (
  <Language language="pt">
    <IndexPage {...props} />
  </Language>
);

export default Index;
